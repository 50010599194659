var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
var Start = /** @class */ (function (_super) {
    __extends(Start, _super);
    function Start() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Start.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("div", { className: "col-md-8" },
                React.createElement("h3", { className: "question-title" }, this.props.course.name),
                React.createElement("h4", { className: "question-subtitle" }, "End of course exam"),
                React.createElement("p", null, "This short test is to confirm that you have achieved the learning outcomes. On successful completion you will receive a certificate which you can print and retain."),
                React.createElement("p", null, "You will have 3 attempts. If you have not achieved the learning outcomes you will be redirected to the payment page to retake the full course."),
                React.createElement("p", null, "When you choose your answer and select next question, you won't be able to change your answer so read every question carefully. We recommend you take notes while taking the exam."),
                React.createElement("p", null, "Select start below to begin the exam.")),
            React.createElement("footer", null,
                React.createElement("button", { className: "next-question button button--secondary ml-auto", onClick: function () { return _this.props.onStart(); } }, "Start exam"))));
    };
    return Start;
}(Component));
export default Start;
