import ExamAttemptAnswer from "./ExamAttemptAnswer";
import _ from "underscore";
import moment from "moment";
var ExamAttempt = /** @class */ (function () {
    function ExamAttempt(obj) {
        this.id = obj.id;
        this.courseId = obj.courseId;
        this.userId = obj.userId;
        this.attemptedAnswers = _.map(obj.attemptedAnswers, function (attemptedAnswerObj) { return new ExamAttemptAnswer(attemptedAnswerObj); });
        this.createdAt = moment(obj.createdAt);
        this.completedAt = obj.completedAt ? moment(obj.completedAt) : undefined;
        this.status = obj.status;
        this.score = obj.score;
    }
    return ExamAttempt;
}());
export default ExamAttempt;
