var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import ExamQuestion from "../../models/ExamQuestion";
import ExamAttempt from "../../models/ExamAttempt";
import Course from "../../models/Course";
import Question from "./Question";
import Start from "./Start";
import _ from "underscore";
import Timer from "./Timer";
import { handleAjaxError } from "../../util";
import qs from "qs";
var Index = /** @class */ (function (_super) {
    __extends(Index, _super);
    function Index(props) {
        var _this = _super.call(this, props) || this;
        _this.onStart = function () {
            window.axios.post("/api/exam_attempts", qs.stringify({ seat_id: _this.state.seatId }))
                .then(function (resp) {
                if (resp.data.success) {
                    _this.setState({ examAttempt: new ExamAttempt(resp.data.exam_attempt) });
                    return;
                }
                if (resp.data.taken_too_many_times) {
                    _this.setState({ showPaymentLink: true });
                    return;
                }
                alert(resp.data.message);
            }).catch(handleAjaxError);
        };
        _this.onAnswerQuestion = function (examAttemptAnswer) {
            if (!_this.state.examAttempt)
                return;
            var updatedExamAttempt = _.clone(_this.state.examAttempt);
            updatedExamAttempt.attemptedAnswers.push(examAttemptAnswer);
            _this.setState({ examAttempt: updatedExamAttempt });
            if (_this.getNextUnansweredQuestion() === undefined) {
                _this.completeExamAttempt();
            }
        };
        var initialState = window.state;
        _this.state = {
            questions: _.map(initialState.questions, function (questionObj) { return new ExamQuestion(questionObj); }),
            examAttempt: initialState.examAttempt ? new ExamAttempt(initialState.examAttempt) : undefined,
            course: new Course(initialState.course),
            seatId: initialState.seatId,
            examAttemptExpiryInMinutes: initialState.examAttemptExpiryInMinutes,
            isCompletingExamAttempt: false,
            showPaymentLink: initialState.showPaymentLink,
            csrfToken: initialState.csrfToken,
            incorrectAnswers: [],
            voucherCode: "",
            certificateUrl: "",
        };
        return _this;
    }
    Index.prototype.completeExamAttempt = function () {
        var _this = this;
        if (!this.state.examAttempt)
            return;
        this.setState({ isCompletingExamAttempt: true });
        var data = qs.stringify({ exam_attempt_id: this.state.examAttempt.id });
        window.axios.post("/api/exam_attempt_completions", data)
            .then(function (resp) {
            if (resp.data.success) {
                return _this.setState({
                    examAttempt: new ExamAttempt(resp.data.exam_attempt),
                    isCompletingExamAttempt: false,
                    incorrectAnswers: resp.data.incorrect_answers,
                    voucherCode: resp.data.voucher_code,
                    certificateUrl: resp.data.certificate_url,
                });
            }
            handleAjaxError(resp);
        }).catch(handleAjaxError);
    };
    Index.prototype.getNextUnansweredQuestion = function () {
        if (!this.state.examAttempt)
            return;
        var providedAnswerIds = _.pluck(this.state.examAttempt.attemptedAnswers, "examAnswerId");
        var answeredQuestionsIds = _.pluck(_.filter(this.state.questions, function (q) {
            return _.chain(q.answers)
                .pluck("id")
                .intersection(providedAnswerIds)
                .value().length > 0;
        }), "id");
        return _.filter(this.state.questions, function (q) { return !_.contains(answeredQuestionsIds, q.id); })[0];
    };
    Index.prototype.render = function () {
        var question = this.getNextUnansweredQuestion();
        if (this.state.showPaymentLink) {
            return React.createElement("div", null,
                "You have failed this course too many times and therefore must purchase the course again. Click the button below to add the course to your cart.",
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement("form", { action: "/shop/add-to-cart", method: "post" },
                    React.createElement("input", { type: "hidden", name: "_token", value: this.state.csrfToken }),
                    React.createElement("input", { type: "hidden", name: "orderable_id", value: this.state.course.id }),
                    React.createElement("input", { type: "hidden", name: "orderable_type", value: "App\\Models\\Course" }),
                    React.createElement("input", { type: "hidden", name: "orderable_quantity", value: "1" }),
                    React.createElement("input", { type: "hidden", name: "seat_to_replace_id", value: this.state.seatId }),
                    React.createElement("button", { type: "submit", className: "button button--secondary" }, "Add course to cart")));
        }
        if (!this.state.examAttempt) {
            return React.createElement(Start, { course: this.state.course, seatId: this.state.seatId, onStart: this.onStart });
        }
        if (!!this.state.examAttempt.completedAt) {
            return (React.createElement("div", null,
                React.createElement("p", null,
                    "You have completed the exam with result: ",
                    React.createElement("b", null, this.state.examAttempt.status),
                    " (",
                    this.state.examAttempt.score,
                    ")"),
                this.state.examAttempt.status == "PASSED" && this.state.voucherCode && React.createElement("div", null,
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("p", null,
                        "You have been awarded with a 10% off voucher code: ",
                        React.createElement("b", null, this.state.voucherCode)),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("a", { href: this.state.certificateUrl, className: "button button--secondary" }, "Click here to download your certificate")),
                this.state.examAttempt.status == "FAILED" && React.createElement("div", null,
                    React.createElement("a", { href: "#", onClick: this.onStart, className: "button button--secondary" }, "Re-take exam"),
                    React.createElement("br", null),
                    React.createElement("br", null),
                    React.createElement("p", null, "Your answers:"),
                    React.createElement("div", { className: "exam-answers" },
                        React.createElement("table", { className: "table table-bordered table-striped" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "Question"),
                                    React.createElement("th", null, "Your answer"))),
                            React.createElement("tbody", null, _.map(this.state.incorrectAnswers, function (answer) {
                                return (React.createElement("tr", null,
                                    React.createElement("td", null, answer.question.name),
                                    React.createElement("td", null, answer.name)));
                            })))))));
        }
        if (this.state.isCompletingExamAttempt) {
            return React.createElement("div", null, "Calculating your result...");
        }
        var examAttemptStartTime = _.clone(this.state.examAttempt.createdAt);
        return (React.createElement("div", null,
            question !== undefined && React.createElement(Question, { question: question, examAttemptId: this.state.examAttempt.id, onAnswer: this.onAnswerQuestion }),
            React.createElement("div", { className: "question-count" },
                "Question ",
                _.indexOf(this.state.questions, question) + 1,
                " of ",
                this.state.questions.length),
            React.createElement(Timer, { dateTimeToCountDownTo: examAttemptStartTime.add(this.state.examAttemptExpiryInMinutes, "minutes"), onElapsed: function () { return alert("Time is up!"); } })));
    };
    return Index;
}(Component));
export default Index;
