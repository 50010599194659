var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import moment from "moment";
import _ from "underscore";
var Timer = /** @class */ (function (_super) {
    __extends(Timer, _super);
    function Timer(props) {
        var _this = _super.call(this, props) || this;
        var remainingSeconds = moment.duration(_.clone(_this.props.dateTimeToCountDownTo)
            .diff(moment(new Date)))
            .asSeconds();
        _this.state = {
            remainingSeconds: remainingSeconds,
        };
        return _this;
    }
    Timer.prototype.componentDidMount = function () {
        var _this = this;
        if (this.state.remainingSeconds > 0) {
            this.interval = setInterval(function () { return _this.setState({
                remainingSeconds: _this.state.remainingSeconds - 1,
            }); }, 1000);
        }
    };
    Timer.prototype.componentWillUnmount = function () {
        if (this.interval) {
            clearInterval(this.interval);
        }
    };
    Timer.prototype.render = function () {
        var remainingSeconds = this.state.remainingSeconds;
        var formattedTime = new Date(remainingSeconds * 1000)
            .toISOString()
            .substr(14, 5);
        if (remainingSeconds <= 0) {
            if (this.interval) {
                clearInterval(this.interval);
            }
            this.props.onElapsed();
            formattedTime = "00:00";
        }
        return React.createElement("div", { className: "timer" },
            "Time remaining: ",
            React.createElement("span", null, formattedTime));
    };
    return Timer;
}(Component));
export default Timer;
