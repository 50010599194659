import ExamAnswer from "./ExamAnswer";
import _ from "underscore";
var ExamQuestion = /** @class */ (function () {
    function ExamQuestion(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.answers = _.map(obj.answers, function (answerObj) { return new ExamAnswer(answerObj); });
        this.order = obj.order;
    }
    return ExamQuestion;
}());
export default ExamQuestion;
