var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from "react";
import ExamAttemptAnswer from "../../models/ExamAttemptAnswer";
import _ from "underscore";
import qs from "qs";
import { handleAjaxError } from "../../util";
var Question = /** @class */ (function (_super) {
    __extends(Question, _super);
    function Question(props) {
        var _this = _super.call(this, props) || this;
        _this.onChangeAnswer = function (answer) {
            _this.setState({ selectedAnswer: answer });
        };
        _this.onClickNextQuestion = function () {
            if (_this.state.selectedAnswer === undefined)
                return;
            var data = qs.stringify({
                exam_answer_id: _this.state.selectedAnswer.id,
                exam_attempt_id: _this.props.examAttemptId,
            });
            _this.setState({ isSendingAnswer: true });
            window.axios.post("/api/exam_attempt_answers", data)
                .then(function (resp) {
                _this.setState({ isSendingAnswer: false });
                if (resp.data.success) {
                    return _this.props.onAnswer(new ExamAttemptAnswer(resp.data.exam_attempt_answer));
                }
                handleAjaxError(resp);
            }).catch(function (resp) {
                handleAjaxError(resp);
                _this.setState({ isSendingAnswer: false });
            });
        };
        _this.state = { selectedAnswer: undefined, isSendingAnswer: false };
        return _this;
    }
    Question.prototype.render = function () {
        var _this = this;
        var selectedAnswer = this.state.selectedAnswer;
        return (React.createElement("div", null,
            React.createElement("h2", { className: "question-title" },
                "Question ",
                this.props.question.order),
            React.createElement("h3", { className: "question-subtitle" }, "Please select one answer below."),
            React.createElement("div", { className: "question-content" }, this.props.question.name),
            React.createElement("ul", { className: "question-answers" }, _.map(this.props.question.answers, function (answer) {
                return (React.createElement("li", { key: answer.id },
                    React.createElement("label", { className: "checkbox" },
                        answer.name,
                        React.createElement("input", { name: "answer", onChange: function () { return _this.onChangeAnswer(answer); }, value: answer.id, checked: selectedAnswer !== undefined && selectedAnswer.id === answer.id, type: "radio" }))));
            })),
            React.createElement("footer", null,
                React.createElement("button", { className: "next-question button button--secondary ml-auto", disabled: selectedAnswer === undefined || this.state.isSendingAnswer, onClick: this.onClickNextQuestion }, "Next question"))));
    };
    return Question;
}(Component));
export default Question;
