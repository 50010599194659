import React from "react";
import ReactDOM from "react-dom";
import ExamIndex from "./components/Exam/Index";
import axios from "axios";
import $ from "jquery";
window.$ = $;
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
var token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else {
    console.error('CSRF token not found.');
}
if (document.getElementById("exam")) {
    ReactDOM.render(React.createElement(ExamIndex, null), document.getElementById("exam"));
}
// ------------------------------------- //
// - Martin jQuery stuff for the theme - //
// ------------------------------------- //
$(document).ready(function () {
    // Open menu
    function showMenu(e) {
        e.preventDefault();
        $('#menu').toggleClass('show');
        $('#mobile-menu').toggleClass('show');
        $('html').toggleClass('overflow');
        $('body').toggleClass('overflow');
        $('a.open-menu > i').toggleClass('via-bars');
        $('a.open-menu > i').toggleClass('via-cross');
    }
    $('a.open-menu').click(function (e) {
        showMenu(e);
    });
    $('a.close-menu').click(function (e) {
        showMenu(e);
    });
    // Open mobile submenu
    $('a.sub-menu').click(function (e) {
        e.preventDefault();
        $(e.currentTarget).next('ul.small-menu').toggleClass('show');
        $(e.currentTarget).toggleClass('open');
        $('#mobile-menu').toggleClass('show-sub-menu');
    });
    $('a.close-sub-menu').click(function (e) {
        e.preventDefault();
        $('ul.small-menu').removeClass('show');
        $('a.sub-menu').removeClass('open');
        $('#mobile-menu').toggleClass('show-sub-menu');
    });
    // Change nav on scroll
    $(function () {
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll >= 1) {
                $("nav").addClass("scroll");
                $("nav .buttons a.button.mr-4").addClass("primary");
                $("nav .buttons a.button .via-bars").removeClass("white");
                $("nav .buttons a.button .via-bars").addClass("black");
                $("div.scroll-icon").addClass("scroll");
            }
            else {
                $("nav").removeClass("scroll");
                $("nav .buttons a.button.mr-4").removeClass("primary");
                $("nav .buttons a.button .via-bars").addClass("white");
                $("nav .buttons a.button .via-bars").removeClass("black");
                $("div.scroll-icon").removeClass("scroll");
            }
        });
    });
});
$(document).ready(function () {
    function showCourseMenu(e) {
        e.preventDefault();
        $('.course aside').toggleClass('show');
        // $('.course article').toggleClass('col-md-9').toggleClass('offset-md-3').toggleClass('col-md-8').toggleClass('offset-md-2');
        $('.course article').toggleClass('offset-md-3').toggleClass('offset-md-2');
    }
    $('a.show-sidebar').click(function (e) {
        showCourseMenu(e);
    });
});
// $(document).on('click', 'a[href="#sidebar"]', function(e) {
//     e.preventDefault();
//     $('#sidebar').toggleClass('show');
// });
$(document).on('click', 'a[href^="#"]', function (e) {
    // target element id
    var id = $(this).attr('href');
    // target element
    var $id = $(id);
    if ($id.length === 0) {
        return;
    }
    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();
    if ($id !== undefined) {
        // top position relative to the document
        var pos = $id.offset().top - 110;
        // animated top scrolling
        $('body, html').animate({ scrollTop: pos });
    }
});
// Stop showreel on close
$("#showreel").on('hidden.bs.modal', function (e) {
    $("#showreel iframe").attr("src", ($("#showreel iframe").attr("src") || ""));
});
$('.edit-address').click(function (e) {
    e.preventDefault();
    $('.edit-address').addClass('hide');
    $('.address-text').addClass('hide');
    $('.address-form').addClass('show');
});
